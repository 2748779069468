@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.purchase_tooltip_gemsbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 100%;
    justify-content: flex-start;

    @media screen and (max-width: 1199px) {
        justify-content: flex-start;
    }

    .purchase_tooltip_gems_img {
        min-width: 75px;
        min-height: 75px;
        display: flex;
        position: relative;

        @media screen and (max-width: 1280px) {
            min-width: 50px;
            min-height: 50px;
        }

        svg {
            margin: 0 !important;
        }
    }

    .purchase_tooltip_gems_title_separates {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
    }

    .purchase_tooltip_gems_details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
    }

    .purchase_tooltip_gemstitles {
        font-weight: 600;
        font-size: 21px;
        line-height: 30px;
        color: #ffffff;
        margin-bottom: 0px;

        @media screen and (max-width: 1280px) {
            font-size: 17px;
            line-height: 30px;
        }

        @media screen and (max-width: 370px) {
            font-size: 15px;
            line-height: 25px;
        }
    }

    .purchase_tooltip_gemsvalues {
        color: #ff7900;
        margin-left: 5px;
    }

    .purchase_tooltip_gemsdetails {
        font-weight: 500;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.02em;
        color: #ffffff;
        margin-bottom: 0px;

        @media screen and (max-width: 370px) {
            font-size: 13px;
        }
    }
}
